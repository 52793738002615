.carousel-item {
  // display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    display: flex;
  }
  img {
    max-height: 250px;
    max-width: 150px;
    height: auto;
    width: auto;
  }
}

.w100{
  width: 100%;
}

.mt-2{
  margin-top: 0.5rem;
}
.mt-3{
  margin-top: 1rem;
}